import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { getDynamicRequired } from '../../helpers';

import styles from './fields.module.css';

const filter = createFilterOptions();

const SelectTextField = ({ field, formData, setFormData, setErrors, refs, errors, dynamicState }) => {
  const options = (field.optionsByDynamicStateKey ? dynamicState[field.optionsByDynamicStateKey] : field.options) || [];
  const uniqueOptions = Array.from(new Map(options.map((item) => [item.label, item])).values());

  const finalOptions = [
    ...(field.showOther ? [{ label: 'Other', value: 'Other' }] : []),
    ...uniqueOptions.filter((item) => item.label !== 'Other'),
  ];

  const foundOption =
    finalOptions.find((o) => formData[field.name] === o.label || formData[field.name] === o.value) ||
    (formData[field.name] && { label: formData[field.name], value: formData[field.name] });

  const isFieldSet = formData[field.name] && formData[field.name].trim().length >= 2;
  const [value, setValue] = useState(foundOption || (isFieldSet && { label: formData[field.name] }) || null);

  useEffect(() => {
    const finalValue = value?.value || '';
    setFormData((prevState) => ({
      ...prevState,
      [field.name]: finalValue,
    }));

    if (field.updateValueOnFields && Array.isArray(field.updateValueOnFields)) {
      for (const fieldToUpdate of field.updateValueOnFields) {
        if (fieldToUpdate.matchValue && fieldToUpdate.matchValue.includes(finalValue)) {
          setFormData((prevState) => ({
            ...prevState,
            [fieldToUpdate.name]: finalValue,
          }));
        }
        if (fieldToUpdate.valueFrom) {
          setFormData((prevState) => ({
            ...prevState,
            [fieldToUpdate.name]: value?.[fieldToUpdate.valueFrom] || '',
          }));
        }
      }
    }

    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
  }, [setFormData, setErrors, field.name, value]);

  const formatOption = (input) => {
    if (field.onAdd?.useOption) {
      return {
        label: field.onAdd.useOption.label.replace('$', input),
        value: field.onAdd.useOption.value.replace('$', input),
      };
    }
    return { label: input, value: input };
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      marginBottom={field.marginBottom}
    >
      <div style={{ width: '100%' }}>
        <Autocomplete
          freeSolo
          fullWidth
          clearOnBlur
          selectOnFocus
          value={value}
          id={field.name}
          key={field.name}
          handleHomeEndKeys
          options={finalOptions}
          getOptionSelected={(opt, value) => opt.value === value.value}
          onChange={(event, newValue) => {
            if (!newValue || (field.disableAdd && (typeof newValue === 'string' || newValue.inputValue))) {
              setValue(null);
              return;
            }

            if (typeof newValue === 'string') {
              setValue(formatOption(newValue));
            } else if (newValue.inputValue) {
              setValue(formatOption(newValue.inputValue));
            } else {
              setValue(newValue);
            }
          }}
          filterOptions={(opts, params) => {
            const filtered = filter(opts, params);
            if (!field.disableAdd) {
              const { inputValue } = params;
              const isExisting = opts.some((opt) => inputValue === opt.label);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Add "${inputValue}"`,
                  value: inputValue,
                });
              }
            }
            return filtered;
          }}
          getOptionLabel={(opt) => {
            if (typeof opt === 'string') {
              return opt;
            } else if (opt.inputValue) {
              return opt.inputValue;
            } else {
              return opt.label;
            }
          }}
          renderOption={(props, opt) => (
            <li key={opt.value || opt.label} {...props}>
              {opt.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              name={field.name}
              label={field.label}
              error={!!errors[field.name]}
              inputRef={refs.current[field.name]}
              required={getDynamicRequired(formData, field.required)}
            />
          )}
        />
      </div>
      {field.helperText && (
        <div>
          <i className={styles.helperText}>{field.helperText}</i>
        </div>
      )}
    </Box>
  );
};

export default SelectTextField;
