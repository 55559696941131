import { useState } from 'react';
import { Alert, Box, Paper, Snackbar, Tab, Tabs } from '@mui/material';

import NotificationsTab from './tabs/notifications.tab';
import Header from '../../../components/header';
import Footer from '../../../components/footer';

import styles from './index.module.css';

function CustomTabPanel(props) {
  const { children, value, index, ...opts } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...opts}>
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    key: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <main className={styles.main}>
      <Header title="Dashboard" setToastOpen={setToastOpen} setToastMessage={setToastMessage} />
      <Paper elevation={2} className={styles.paper}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.tabsBg}>
          <Tabs value={value} onChange={handleChange} aria-label="Dashboard Navigation">
            <Tab label="Patient Notifications" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <NotificationsTab setToastOpen={setToastOpen} setToastMessage={setToastMessage} />
        </CustomTabPanel>
      </Paper>
      <Footer />
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastMessage.severity} sx={{ width: '100%' }}>
          {toastMessage.label}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Dashboard;
