import { Box, TextField } from '@mui/material';

import { autoFormatters, getDynamicRequired } from '../../helpers';

const TextInputField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: field.autoFormat ? autoFormatters[field.autoFormat](value) : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={field.marginTop}
      marginBottom={field.marginBottom}
    >
      <TextField
        fullWidth
        slotProps={{
          htmlInput: {
            maxLength: field.maxLength,
          },
          inputLabel: {
            shrink: true,
            style: {
              width: '120%',
              overflow: 'visible',
              whiteSpace: 'normal',
              textOverflow: 'unset',
            },
          },
          formHelperText: {
            sx: field.helperTextSx,
          },
        }}
        sx={field.sx}
        id={field.name}
        key={field.name}
        name={field.name}
        type={field.type}
        label={field.label}
        value={formData[field.name]}
        onChange={handleInputChange}
        error={!!errors[field.name]}
        inputRef={refs.current[field.name]}
        required={getDynamicRequired(formData, field.required)}
        helperText={(!!errors[field.name] && field.helperText) || (field.showHelperText && field.helperText)}
      />
    </Box>
  );
};

export default TextInputField;
