import { useCallback, useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { isValidBase64Image } from '../../helpers';

import styles from './fields.module.css';

const SignatureField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const [showSignatureName, setShowSignatureName] = useState(false);
  const sigPadRef = refs.current[field.name];
  const fieldValue = formData[field.name];
  const debounceTimer = useRef(null);
  const options = field.options;

  const signatureOptions = options && options.signatureName;
  const signatureName = formData[signatureOptions?.main?.[0]]
    ? `${formData[signatureOptions?.main?.[0]]} ${formData[signatureOptions?.main?.[1]]}`
    : `${formData[signatureOptions?.fallback?.[0]]} ${formData[signatureOptions?.fallback?.[1]]}`;

  const saveSignature = useCallback(() => {
    if (!sigPadRef.current || sigPadRef.current.isEmpty()) return;

    const base64 = sigPadRef.current.toDataURL('image/webp', 0.25);
    if (!isValidBase64Image(base64)) {
      setErrors((prevErrors) => ({ ...prevErrors, [field.name]: true }));
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      [field.name]: base64,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
    setShowSignatureName(true);
  }, [sigPadRef, field.name, setFormData, setErrors]);

  const handleClear = () => {
    sigPadRef.current?.clear();
    setFormData((prevState) => ({
      ...prevState,
      [field.name]: null,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
    setShowSignatureName(false);
  };

  const handleEnd = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      saveSignature();
    }, 250);
  };

  useEffect(() => {
    if (sigPadRef.current) {
      if (fieldValue && typeof fieldValue === 'string' && fieldValue.startsWith('data:image/')) {
        sigPadRef.current.fromDataURL(fieldValue);
        setShowSignatureName(true);
      }
    }

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [sigPadRef, fieldValue]);

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      marginTop={field.marginTop}
      marginBottom={field.marginBottom}
      key={`signature-box-${field.name}`}
    >
      <Box className={styles.previewContainer}>
        <>
          <SignaturePad
            ref={sigPadRef}
            id={field.name}
            key={field.name}
            onEnd={handleEnd}
            canvasProps={{ className: styles.signaturePad }}
          />
          <IconButton className={styles.clearButton} onClick={handleClear} aria-label="Clear" title="Clear">
            <Close />
          </IconButton>
        </>
      </Box>
      {showSignatureName && signatureName && (
        <div className={styles.signatureName} key={field.name}>
          <div>{signatureName}</div>
          <div>{new Date().toLocaleDateString()}</div>
        </div>
      )}
      <div>
        <label className={styles.buttonLabel}>
          {field.label}
          {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
        </label>
      </div>
      {!!errors[field.name] && <div className={styles.helperText}>{field.helperText}</div>}
    </Box>
  );
};

export default SignatureField;
