import { IconButton, Avatar, Menu, MenuItem, Divider } from '@mui/material';
import { Dashboard, Settings, Logout, Api } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const ProfileMenu = ({ handleDashboard, handleProfile, handleApi, handleLogout }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsSuperAdmin(decodedToken.roles && decodedToken.roles.includes('super_admin'));
      } catch (error) {
        console.error('❌ Failed to decode token', error);
      }
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutHandler = () => {
    handleClose();
    handleLogout();
  };

  const handleApiHandler = () => {
    handleClose();
    handleApi();
  };

  const handleProfileHandler = () => {
    handleClose();
    handleProfile();
  };

  const handleDashboardHandler = () => {
    handleClose();
    handleDashboard();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Avatar alt="Profile" src="/avatar-icon.webp" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: '0px',
            },
          },
        }}
      >
        <MenuItem onClick={handleDashboardHandler}>
          <Dashboard fontSize="small" sx={{ marginRight: '6px' }} /> Dashboard
        </MenuItem>
        {isSuperAdmin && (
          <MenuItem onClick={handleApiHandler}>
            <Api fontSize="small" sx={{ marginRight: '6px' }} /> API Manager
          </MenuItem>
        )}
        <MenuItem onClick={handleProfileHandler}>
          <Settings fontSize="small" sx={{ marginRight: '6px' }} /> Profile Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutHandler}>
          <Logout fontSize="small" sx={{ marginRight: '6px' }} /> Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
