import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Paper,
  Button,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  Pagination,
  InputAdornment,
  TableContainer,
  TableSortLabel,
} from '@mui/material';
import { Close, Search, Delete } from '@mui/icons-material';

import { formatPhoneNumber, getDateTimestamp } from '../../../../helpers';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Loader from '../../../../components/loader';

import styles from '../index.module.css';

const NotificationsTab = ({ setToastOpen, setToastMessage }) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [notifications, setNotifications] = useState([]);
  const [handleAction, setHandleAction] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [description, setDescription] = useState('');
  const [actionLabel, setActionLabel] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const itemsPerPage = 10;

  const token = sessionStorage.getItem('authToken');
  const clientId = sessionStorage.getItem('clientId');

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (sortConfig.key) {
      const valueA = a[sortConfig.key].toString();
      const valueB = b[sortConfig.key].toString();
      if (valueA < valueB) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredNotifications = sortedNotifications.filter((notification) =>
    [`${notification.first_name} ${notification.last_name}`, notification.phone_number, notification.status].some((field) =>
      field.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  const paginatedNotifications = filteredNotifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    const getNotifications = async () => {
      const res = await fetch(`/api/notifications`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Client-ID': clientId,
        },
      });

      if (res.ok) {
        const data = await res.json();
        setNotifications(data.map((r) => ({ ...r, phone_number: formatPhoneNumber(r.phone_number) })));
      }

      setLoading(false);
    };

    getNotifications();
    const intervalId = setInterval(getNotifications, 5000);
    return () => clearInterval(intervalId);
  }, [clientId, token]);

  const handleViewMessage = (notification) => {
    setTitle('Message Sent to Patient');
    setMessage(`Successfully sent message on: ${getDateTimestamp(notification.created_at)}`);

    let description = `To: ${notification.first_name} ${notification.last_name}\n`;
    if (notification.email_address) {
      description += `Email: ${notification.email_address}\n`;
    }
    if (notification.phone_number) {
      description += `Phone: ${notification.phone_number}\n`;
    }

    setDescription(`${description}\n${notification.message}`);
    setHandleAction(() => () => handleResendMessage(notification));
    setActionLabel('Resend');
    setDialogOpen(true);
  };

  const handleDelete = (notification) => {
    setTitle('Delete Patient Notification');
    setMessage('Are you sure you want to delete this record?');

    let description = `${notification.first_name} ${notification.last_name}`;
    if (notification.phone_number) {
      description += ` - ${notification.phone_number}`;
    }

    setDescription(description);
    setHandleAction(() => () => handleDeleteNotification(notification));
    setActionLabel('Delete');
    setDialogOpen(true);
  };

  const handleResendMessage = async (notification) => {
    const res = await fetch('/api/notifications/prepare', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientId,
        ignoreExisting: true,
        notificationId: notification.notification_id,
      }),
    });
    const data = await res.json();
    const finalMessage = notification.message.replace(/hoys\.io\/[^\s]+/g, data.url);

    if (notification.email_address) {
      await fetch('/api/mail/send', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: notification.client_name,
          email: notification.email_address,
          subject: 'Your Online Patient Intake Form',
          message: { text: finalMessage, html: finalMessage.replace(/\n/g, '<br />') },
        }),
      });
    }

    if (notification.phone_number) {
      await fetch('/api/sms/send', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: notification.phone_number, smsMessage: finalMessage }),
      });
    }

    await Promise.all([
      fetch(`/api/notifications/${notification.notification_id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Client-ID': clientId,
        },
        body: JSON.stringify({
          forceUpdate: true,
          status: 'Message Resent',
          created_at: new Date().toISOString(),
        }),
      }),
      fetch(`/api/messages/${notification.message_id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ clientId, message: finalMessage }),
      }),
    ]);

    setToastMessage({ label: 'Successfully resent message to patient!', severity: 'success' });
    setDialogOpen(false);
    setToastOpen(true);
  };

  const handleDeleteNotification = async (notification) => {
    await fetch(`/api/admin/notifications/${notification.notification_id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    setNotifications(notifications.filter((n) => n.notification_id !== notification.notification_id));
    setToastMessage({ label: 'Successfully deleted patient notification!', severity: 'success' });
    setDialogOpen(false);
    setToastOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <section className={styles.sectionColumn}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TextField
            fullWidth
            id="search"
            label="Search"
            placeholder="Search notifications"
            sx={{ mb: 4 }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchQuery ? (
                    <IconButton onClick={() => setSearchQuery('')}>
                      <Close />
                    </IconButton>
                  ) : (
                    <Search />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'first_name'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('first_name')}
                    >
                      Full Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'phone_number'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('phone_number')}
                    >
                      Phone Number
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '10%' }}>Message</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'status'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('status')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'updated_at'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('updated_at')}
                    >
                      Last Updated
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'location_provider'}
                      direction={sortConfig.direction}
                      onClick={() => handleSort('location_provider')}
                    >
                      Location / Doctor
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell align="center">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedNotifications.map((notification, index) => (
                  <TableRow key={index}>
                    <TableCell
                      title={notification.notification_id}
                    >{`${notification.first_name} ${notification.last_name}`}</TableCell>
                    <TableCell>{notification.phone_number}</TableCell>
                    <TableCell>
                      <Button variant="outlined" size="small" onClick={() => handleViewMessage(notification)}>
                        View
                      </Button>
                    </TableCell>
                    <TableCell>{notification.status}</TableCell>
                    <TableCell>{getDateTimestamp(notification.updated_at)}</TableCell>
                    <TableCell>{notification.location_provider}</TableCell>
                    {/* <TableCell align="center">
                      <Button className={styles.deleteBtn} onClick={() => handleDelete(notification)}>
                        <Delete titleAccess="Delete" />
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {filteredNotifications.length === 0 && (
              <Box display="flex" flexDirection="column" alignItems="center" m={4}>
                <Typography variant="h6" color="textSecondary" sx={{ fontWeight: '300', fontSize: '18px' }}>
                  No Patient Notifications Available
                </Typography>
              </Box>
            )}
          </TableContainer>
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            className={styles.pagination}
            count={Math.ceil(filteredNotifications.length / itemsPerPage)}
          />
          <ConfirmDialog
            onConfirm={() => setDialogOpen(false)}
            onClose={() => setDialogOpen(false)}
            description={description}
            actionLabel={actionLabel}
            onAction={handleAction}
            confirmLabel="Close"
            hideCloseBtn={true}
            message={message}
            open={dialogOpen}
            title={title}
          />
        </>
      )}
    </section>
  );
};

export default NotificationsTab;
