import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useEffect, useRef } from 'react';

import styles from './fields.module.css';

const CheckboxField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const checkboxRefs = useRef({});

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => {
      const selectedValues = prevState[name];
      if (value === 'None' && checked) {
        return {
          ...prevState,
          [name]: ['None'],
        };
      } else if (checked) {
        const updatedValues = selectedValues.includes('None') ? selectedValues.filter((val) => val !== 'None') : selectedValues;
        return {
          ...prevState,
          [name]: [...updatedValues, value].filter((val) => !!val),
        };
      } else {
        return {
          ...prevState,
          [name]: selectedValues.filter((val) => val !== value),
        };
      }
    });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const focusCheckboxGroup = () => {
    const firstCheckbox = Object.values(checkboxRefs.current)[0];
    if (firstCheckbox) {
      firstCheckbox.focus();
    }
  };

  const getInitials = (name) => {
    const parts = name.split(/\s+|-/);
    let firstInitial = parts[0]?.charAt(0) || '';
    let lastInitial = '';
    if (parts.length > 1) {
      lastInitial = parts[parts.length - 1]?.charAt(0) || '';
    }
    return (firstInitial + lastInitial).toUpperCase();
  };

  const fieldInitials = field.initials;
  let initialsName = null;
  if (fieldInitials && Object.keys(fieldInitials).length) {
    initialsName = formData[fieldInitials?.main?.[0]]
      ? `${formData[fieldInitials?.main?.[0]]} ${formData[fieldInitials?.main?.[1]]}`
      : `${formData[fieldInitials?.fallback?.[0]]} ${formData[fieldInitials?.fallback?.[1]]}`;
  }

  useEffect(() => {
    if (initialsName) {
      setFormData((prevState) => ({
        ...prevState,
        ['consentInitials']: getInitials(initialsName),
      }));
    }
  }, [initialsName]);

  useEffect(() => {
    if (!Array.isArray(formData[field.name]) && typeof formData[field.name] === 'string') {
      setFormData((prevState) => {
        const currentValue = prevState[field.name];
        if (typeof currentValue === 'string') {
          return {
            ...prevState,
            [field.name]: currentValue.split(',').map((v) => v.trim()),
          };
        }
        return prevState;
      });
    }
  }, [formData[field.name]]);

  refs.current[field.name] = { focus: focusCheckboxGroup };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <FormControl fullWidth error={!!errors[field.name]} sx={{ maxWidth: 600, textAlign: 'left' }} key={field.name}>
        {field.label && (
          <FormLabel
            id="checkbox-group-label"
            sx={{ marginBottom: '10px', color: 'black', fontWeight: field.boldLabel ? 'bold' : 'normal', textAlign: field.alignLabel || 'center' }}
          >
            {field.renderLabelAsHtml ? (
              <span
                className={styles.renderAsHtml}
                dangerouslySetInnerHTML={{
                  __html: field.label,
                }}
              />
            ) : (
              field.label
            )}
            {(field.required || field.allRequired) && <span style={{ color: 'red', whiteSpace: 'nowrap' }}>&nbsp;*</span>}
          </FormLabel>
        )}
        <FormGroup
          sx={{
            display: 'grid',
            gap: 1,
            ...(field.centerItems
              ? {
                  justifyItems: 'center',
                  '> .MuiFormControlLabel-root': {
                    marginRight: '0px',
                  },
                }
              : {}),
          }}
          className={field.oneColumn ? styles.formGroupOneColumn : styles.formGroup}
        >
          {field.options.map((o) => {
            const initials = (initialsName && getInitials(initialsName)) || false;
            const isChecked = formData[field.name]?.includes(o.value) || false;
            return (
              <FormControlLabel
                key={o.value}
                sx={{
                  '& .MuiFormControlLabel-label': { fontSize: '14px', textAlign: initials ? 'justify' : 'left' },
                }}
                control={
                  initials ? (
                    <>
                      <input
                        type="checkbox"
                        value={o.value}
                        name={field.name}
                        checked={isChecked}
                        className={styles.hiddenInput}
                        ref={(el) => {
                          if (el) {
                            checkboxRefs.current[o.value] = el;
                          } else {
                            delete checkboxRefs.current[o.value];
                          }
                        }}
                      />
                      <Box
                        className={styles.initialsField}
                        onClick={() =>
                          handleCheckboxChange({ target: { name: field.name, value: o.value, checked: !isChecked } })
                        }
                        sx={{
                          color: isChecked ? 'var(--client-primary-color)' : 'white',
                          borderTop: `2px solid ${isChecked ? 'white' : 'gray'}`,
                          borderLeft: `2px solid ${isChecked ? 'white' : 'gray'}`,
                          borderRight: `2px solid ${isChecked ? 'white' : 'gray'}`,
                          borderBottom: `2px solid ${isChecked ? 'gray' : 'gray'}`,
                        }}
                      >
                        {initials}
                      </Box>
                    </>
                  ) : (
                    <Checkbox
                      value={o.value}
                      name={field.name}
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                      inputRef={(el) => {
                        if (el) {
                          checkboxRefs.current[o.value] = el;
                        } else {
                          delete checkboxRefs.current[o.value];
                        }
                      }}
                    />
                  )
                }
                label={
                  <div
                    onClick={
                      initials
                        ? () => handleCheckboxChange({ target: { name: field.name, value: o.value, checked: !isChecked } })
                        : undefined
                    }
                  >
                    {field.allRequired && <span style={{ color: 'red', whiteSpace: 'nowrap' }}>*&nbsp;</span>}
                    {o.label === 'None' ? <strong>{o.label}</strong> : o.label}
                  </div>
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CheckboxField;
